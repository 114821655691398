import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "hasSalaryInput",
        "salaryContainer",
        "salarySlider",
        "annualSalary",
        "monthlySalary",
        "hourlySalary",
    ]

    connect() {
        this.toggleRange() // init values
        this.salarySliderTarget.value = 21000
        this.salarySliderTarget.style.backgroundSize = "79% 100%"
        this.update() // init values
    }

    toggleRange() {
        const isHidden = !this.hasSalaryInputTarget.checked
        this.salaryContainerTarget.classList.toggle("hidden", isHidden)
        this.salarySliderTarget.setAttribute("name", isHidden ? "" : "minSalary")
    }

    update() {
        const annualSalary = this.salarySliderTarget.value
        const monthlySalary = (annualSalary / 12).toFixed(2)
        const hourlySalary = (annualSalary / (12 * 151.67)).toFixed(2)

        this.annualSalaryTarget.textContent = parseFloat(annualSalary).toLocaleString("fr-FR")
        this.monthlySalaryTarget.textContent = parseFloat(monthlySalary).toLocaleString("fr-FR")
        this.hourlySalaryTarget.textContent = parseFloat(hourlySalary).toLocaleString("fr-FR")
        this.updateSliderProgressBar()
    }
    updateSliderProgressBar() {
        let percentage = (this.salarySliderTarget.value / this.salarySliderTarget.max) * 100
        this.salarySliderTarget.style.backgroundSize = `${100 - percentage}% 100%`
    }
}
