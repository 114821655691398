import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        const toggleMenu = this.element.querySelector("button")
        const menu = this.element.querySelector("#navbar-content")
        if (toggleMenu) {
            toggleMenu.addEventListener("click", () => {
                menu.classList.toggle("hidden")
            })
        }
    }
}
