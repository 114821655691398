import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["menuToToggle"]

    static values = {
        whenMatching: String,
    }

    toggleSmallScreensMenu() {
        const menu = this.menuToToggleTarget
        menu.classList.toggle("hidden")
        menu.classList.toggle("flex")
    }

    toggleSubmenu(event) {
        event.preventDefault()
        if (this.#shouldToggleOnClick()) {
            const submenu = event.target.nextElementSibling
            submenu.classList.toggle("hidden")
        }
    }

    #shouldToggleOnClick() {
        return !window.matchMedia(this.whenMatchingValue).matches
    }
}
